

.slider__item{
    max-width: 100%;
    height: 650px;
}

.slider__item-01{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)), url('../assets//all-images//cars-img//LAND-CRUISER-SMK-removebg-preview.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slider__item-02{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)), url('../assets//all-images//cars-img//carousel-2-Copy.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.slider__item-03{
    background: linear-gradient(rgb(0, 13, 107, 0.5), rgb(0, 13, 107, 0.5)), url('../assets//all-images//cars-img//mercedes-offer.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.hero__slider{
    overflow: hidden;
}

.slider__content{
    padding-top: 150px;
}

.slider__content h1{
    font-size: 3.2rem;
    font-weight: 600;
}

.reserve__btn{
    background: #fff !important;
    color: #000d6b !important;
    border: none;
    outline: none;
}

.reserve__btn a{
    text-decoration: none;
    font-weight: 600;
    color: #000d6b;
}


@media only screen and (max-width: 992px){
    .slider__item{
        height: 530px;
        width: 100%;
    }

    .slider__content{
        margin-top: -70px;
    }

    .slider__content h1{
        font-size: 2rem;
    }
}

@media only screen and (max-width: 768px){
    .slider__item{
        height: 450px;
    }

    .slider__content h1{
        font-size: 1.5rem;
    }
}